import {useParams, useHistory} from "react-router-dom";
import { Layout, Button, Form, Input, Loading, MessageBox, Notification } from 'element-react';
import { useTranslation } from 'react-i18next';
import {useState, useRef, useEffect} from 'react';
import { Auth } from 'aws-amplify';
function RegisterConfirm(){
    const { t } = useTranslation();
    let history = useHistory();
    let { user, type } = useParams();
    const form = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [formInputs, setFormInputs] = useState(
        {
            code: ''
        }
    )
    const [rules, setRules] = useState(null);
    
    useEffect(() => {
        setRules(
            {
                code: [
                    { required: true, message: t('register-form.validate-generic') }
                  ]
            }
        )
    }, [t])

    const handleOnSubmit = () =>{
        console.log('sending register code');
        form.current.validate(async (valid) => {
            if (valid) {
                setLoading(true);
                setLoadingBtn(true);
                console.log(formInputs.code);
                try{
                    await Auth.confirmSignUp(user, formInputs.code);
                    setLoading(false);
                    //send welcome email
                    try{
                        if(type === 'B'){
                            console.log('sending welcome email');
                            const templateVars = {
                                name: user
                            }
                            const emailData = {
                                templateName: 'welcomeBuyer',
                                templateLanguage: 'en',
                                emailFrom: 'noreply@bainub.com',
                                recipients: [user],
                                templateVars: templateVars,
                                subject: 'Welcome to Bainub!'
                            }
                            const response = await fetch('https://files.bainub.com/newNotification/sendTemplate', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(emailData),
                            });
                            await response.json();
                        }else{
                            const templateVars = {
                                name: user
                            }
                            const emailData = {
                                templateName: 'bienvenidoVendedor',
                                templateLanguage: 'es',
                                emailFrom: 'noreply@bainub.com',
                                recipients: [user],
                                templateVars: templateVars,
                                subject: 'Bienvenido a Bainub!'
                            }
                            const response = await fetch('https://files.bainub.com/newNotification/sendTemplate', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(emailData),
                            });
                            await response.json();
                        }
                    }catch(err){
                        console.log(err);
                    }
                    setTimeout(function(){ 
                        history.push("/RegistrationSuccess/"+type);
                    }, 3000);
                }catch(err){
                    console.log(err);
                    setLoadingBtn(false);
                    setLoading(false);
                    let finalMsg = 'Ocurrio un error el tratar de registrar su usuario, por favor intente más tarde';
                    if(err.code === 'CodeMismatchException'){
                        finalMsg = 'Código incorrecto';
                    }else{
                        if(err.code === 'NotAuthorizedException'){
                            finalMsg = err.message;
                        }
                    }
                    
                    MessageBox.alert(
                        finalMsg, 'Error',
                        {
                        type: 'error'
                        }
                    );
                }
            } else {
              console.log('error submit!!');
              return false;
            }
          });
    }

    const handleSendCode = async() =>{
        setLoading(true);
        try {
            await Auth.resendSignUp(user);
            setLoading(false);
            Notification({
                title: t('confirm-register-form.resend-code-msg-title'),
                message: t('confirm-register-form.resend-code-msg-body'),
                type: 'success',
                offset: 100
            });
            console.log('code resent successfully');
        } catch (err) {
            setLoading(false);
            Notification({
                title: err.code,
                message: err.message,
                type: 'error',
                offset: 100
            });
            console.log('error resending code: ', err);
        }
    }

    if(loading)
        return <Loading loading={loading} fullscreen={true} />
    return(
        <div>
            <Layout.Row gutter="10" type="flex" className="first-title-row" >
                <Layout.Col span="24" xs="24" sm="24" md="24" lg="24">
                    <h2 className="form-title" >{t('confirm-register-form.title')}</h2>
                    <label className="form-subtitle" >{t('confirm-register-form.subtitle')}</label>
                    <label className="el-form-item__label" >{t('confirm-register-form.message')} <b>{user}</b>.</label>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row >
                <Form ref={form} model={formInputs} className="en-US" rules={rules} label-position="top">
                    <Layout.Row >
                        <Layout.Col xs="24" sm="24" md="8" lg="8">
                            <Form.Item prop="code">
                                <Input 
                                    value={formInputs.code} 
                                    trim={true}
                                    onChange={(v) => {setFormInputs({...formInputs, code: v })}} 
                                    placeholder={t('confirm-register-form.code-placheholder')}/>
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row className="isolated-row" type="flex" justify="right" align="middle">
                        <Form.Item align="middle" justify="center">
                            <a onClick={handleSendCode} href="#" className="secondary-button">
                                {t('confirm-register-form.resend-code')}
                            </a>
                            <Button onClick={handleOnSubmit} type="primary" loading={loadingBtn} className="primary-button">
                                {t('confirm-register-form.validate-code')}
                            </Button>
                        </Form.Item>
                    </Layout.Row>
                </Form>
            </Layout.Row>
            <Layout.Row gutter="10" type="flex" className="first-title-row align-left" >
                <Layout.Col span="24" xs="24" sm="24" md="15" lg="15">
                    <label className="form-subtitle" >{t('confirm-register-form.footer-title')}</label>
                    <label className="el-form-item__label align-left" >{t('confirm-register-form.footer-text')}</label>
                </Layout.Col>
            </Layout.Row>
        </div>
    )
}

export default RegisterConfirm;