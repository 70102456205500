const myAccountMenuItems = {
    admin: [
        {
            
                label: "my-account.menu.my-account",
                linkTo: "/my-account",
                icon: ""
            },
            {
                label: "my-account.menu.navigation-preferences",
                linkTo: "/navigation-preferences",
                icon: ""
            },
            {
                label: "my-account.menu.my-favorites",
                linkTo: "/my-favorites",
                icon: ""
            },
            {
                label: "footer.help-and-support",
                linkTo: "/help-support-menu",
                icon: ""
            }
        
    ],
    buyer: [
        {
            label: "my-account.menu.dashboard",
            linkTo: "/dashboard",
            icon: ""
        },
        {
            label: "my-account.menu.account",
            linkTo: null,
            icon: "",
            submenu: [
                {
                    label: "my-account.menu.my-account",
                    linkTo: "/my-account",
                    icon: ""
                },
                {
                    label: "my-account.menu.payment-detail",
                    linkTo: "/payment-detail",
                    icon: ""
                },
                {
                    label: "my-account.menu.history-logs",
                    linkTo: "/history-logs",
                    icon: ""
                },
                {
                    label: "my-account.menu.navigation-preferences",
                    linkTo: "/navigation-preferences",
                    icon: ""
                },
                {
                    label: "my-account.menu.site-configuration",
                    linkTo: "/site-configuration",
                    icon: ""
                }
            ]
        }
    ]
}

export {
    myAccountMenuItems
};