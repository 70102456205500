import { Layout, Menu } from 'element-react';
import { useTranslation } from 'react-i18next';
import { useHistory} from "react-router-dom";
import { myAccountMenuItems } from '../utils/MenuCatalogs'
import userStore from '../store/user';
import menuStore from '../store/menu-selected';

const MyAccountLeftMenu = () =>{
    const {t} = useTranslation();
    let history = useHistory();
    const { user } = userStore();  
    const {menuIndex, setMenuIndex} = menuStore();
    function renderMenu () {
        console.log("rendering menu")
        console.log('menuIndex :: ' + menuIndex);
        //let menuItems =user.type == "S" ?  myAccountMenuItems.admin : myAccountMenuItems.buyer;
        //https://trello.com/c/P7Q95FYX/242-menu-izquierdo-de-my-account
        let menuItems = myAccountMenuItems.admin;
        return createMenuItems(menuItems)
    }

    function createMenuItems(items) {
        return items.map((item, index) => {
            if(!item.submenu) {
                 let clazzName = item.linkTo === menuIndex ? "cursor menu-selected" : "cursor"
                return (
                    <div onClick={() => {
                            setMenuIndex(item.linkTo);
                            history.push(item.linkTo);
                        }}
                    >
                        <Menu.Item className="left-menu-item" index={item.linkTo} key={index}>
                            <label className={clazzName}>
                                {t((item.label))}
                            </label>
                        </Menu.Item>
                    </div>
                    
                )
            } else {
                return (
                    <Menu.SubMenu index="2" title={<label className="cursor">{t((item.label))}</label>} key={index}>
                        { createMenuItems(item.submenu) }
                    </Menu.SubMenu>
                )
            }
        })
    }
    
    return(
        <Layout.Row className="tac main-left-menu">
            <Layout.Col span={24}>
                <div className="sidenav">
                    <Menu defaultActive="/my-account" className="el-menu-vertical-demo">
                        <Menu.Item index="" className="left-menu-title m-t-md">
                            {t('my-account.title')}
                        </Menu.Item>
                        {renderMenu()}
                    </Menu>
                </div>
            </Layout.Col>
        </Layout.Row>
    )
}

export default MyAccountLeftMenu;