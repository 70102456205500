import React from 'react';
import { Layout } from 'element-react';
import shop from '../assets/imgs/shop.png';
import {useParams} from "react-router-dom";


const LoginIcon = (props) => {
  let { type } = useParams();
  return (
    <Layout.Row type="flex">
         <Layout.Col className="text-center" span="24" xs="20" sm="20" lg="24">
                        <img className="w20" src={shop}/>   
                        <h1>Congratulations!</h1>
                        <h3>Your account was successfully registred.</h3>
                        <p>You are ready to set up your Business Microsite and display your products in Bainub.<br></br>
                           Please login into Bainub with your recently created credentials.<br></br><br></br>  
                        </p>
                        <div id="main-login-button">
                            <a className="el-button el-button--primary" href={type == 'S' ? "/dashboard" : "/login"}>
                                  <i className='el-icon-login'></i> <span>Log in</span>
                            </a>
                        </div>
                        
         </Layout.Col>
    </Layout.Row>
  )
}

export default LoginIcon;