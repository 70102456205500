import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { getCompany } from '../graphql/queries';
import { useTranslation } from 'react-i18next';

import { S3Image } from '../components/S3Image';
import { Link, useHistory } from "react-router-dom";
import { Card } from 'element-react';

export default function ProductSwiperItem({ product }) {
    let history = useHistory();
    const { t } = useTranslation();

    const [[loadingSupplier , setLoadingSupplier], [supplier , setSupplier]]  = [useState(true), useState(null)]

    useEffect(function () {
        setLoadingSupplier(true)
        loadSupplier()
    }, [])

    async function loadSupplier() {
        console.log("loading supplier ...");
         const supplierResult = await API.graphql({
            query: getCompany,
            variables: { id: product.company_id },
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM
          });
        setSupplier(supplierResult.data.getCompany)
        setLoadingSupplier(false)
    }
    
    //TODO: DEFINE A DEFAULT IMAGE
    const defaultImageKey = "/default-images/swiper-product-default-img.png"

    const redirectToProductPage = () => history.push("/product-detail/"+product.id) 
    const redirectToSupplier = () => { window.open("/company-store/"+product.company_id, "_blank") }//history.push("/company-store/"+product.company_id) 
    
    const principalImageThemeConfig = {
        photoImg: {
            maxWidth: "160px",
            maxHeight: "160px",
            borderRadius: "5%"
        }
    }

    const productImageKey = product && product.photo && product.photo[0] && product.photo[0].key ? product.photo[0].key : defaultImageKey;

    return (
        <Card className="product-swiper-item">
            <div className="text-center" >
                {<S3Image imgKey={productImageKey} theme={principalImageThemeConfig} level="public" onClick={redirectToProductPage} className="cursor"/>}
                {/*<img src={'https://d38sueskhppud2.cloudfront.net/public/'+productImageKey} alt={product.name} onClick={redirectToProductPage} className="cursor" style={{...principalImageThemeConfig.photoImg}}/>*/}
            </div>
            <p className="product-swiper-item-productname">{product.name}</p>
            <p className="product-swiper-item-company">{t('home-page.by-label')} <b className="primary-color cursor" onClick={redirectToSupplier}>{ loadingSupplier ? "loading ..." : supplier.name}</b></p>
            <p className="product-swiper-item-price">$ {product.min_price} - $ {product.max_price} USD</p>
        </Card>
    )
}
