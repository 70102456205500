import React from 'react';
import ContactUs from './ContactUs';
import HelpAndSupportComponent from '../components/HelpAndSupportComponent';

function HelpAndSupport({menu}) {
    return (
        <>
            <HelpAndSupportComponent menu={menu}/>
            <ContactUs menu={menu} hideTitle={true}/>
        </>
    );
}

export default HelpAndSupport;
